<template>
  <div v-if="Object.keys(blog).length" class="cws-content">
    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card
            :img-src="blog.img"
            img-top
            img-alt="Blog Detail Pic"
            :title="blog.title"
          >
            <div class="my-1 py-25">
              <b-link
                v-for="category in blog.categories"
                :key="category.id"
                :to="'/announcements/' + category.id"
              >
                <b-badge pill class="mr-75" :variant="tagsColor(category.id)">
                  {{ category.title }}
                </b-badge>
              </b-link>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div class="blog-content" v-html="blog.description" />
            <hr class="my-2" />
          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->
  </div>
</template>

<script>
import axios from '@/libs/axios'
import { BLink, BCard, BRow, BCol, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { blogUtils } from '@core/mixins/ui/blog'

export default {
  components: {
    BLink,
    BCard,
    BRow,
    BCol,
    BBadge,
  },
  directives: {
    Ripple,
  },
  mixins: [blogUtils],
  data() {
    return {
      blog: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  created() {
    axios.get(`announcements/${this.id}`).then((res) => {
      this.blog = res.data
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
